.hide-md-down {
  display: none;
}

ol {
  padding: 0 20px;
  list-style-position: outside;
}

ol li {
  padding-left: 24px;
}

ol.style-dash {
  list-style: square outside
    url("data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==");
}

a {
  color: inherit;
}
