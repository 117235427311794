.circle-grow.transition-appear {
  clip-path: circle(0px);
}
.circle-grow.transition-appear.transition-appear-active {
  clip-path: circle(100%);
}

.circle-grow.transition-leave {
  opacity: 1;
}

.circle-grow.transition-leave.transition-leave-active {
  opacity: 1;
}
