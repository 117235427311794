.cross-fade.transition-appear {
  opacity: 0;
}
.cross-fade.transition-appear.transition-appear-active {
  opacity: 1;
}

.cross-fade.transition-leave {
  opacity: 1;
}

.cross-fade.transition-leave.transition-leave-active {
  opacity: 0;
}
