.transition-item.fade-in-out {
  opacity: 0;
}

.fade-in-out.transition-appear.transition-appear-active {
  opacity: 1;
}

.fade-in-out.transition-leave {
  opacity: 1;
}

.fade-in-out.transition-leave.transition-leave-active {
  opacity: 0;
}
