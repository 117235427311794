.transition-item {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 1;
  transition: transform 0.7s ease-in-out;
  will-change: transform;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.transition-item.slide-up,
.transition-item.slide-down,
.transition-item.slide-left,
.transition-item.slide-right {
  opacity: 1;
}
